/* eslint-disable react/jsx-no-bind */
import { graphql, useStaticQuery } from 'gatsby'
import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react'
import Cookies from 'universal-cookie'
import { navigate } from '@helpers/link'
import PropTypes from 'prop-types'
import { useDispatch, useStore } from 'react-redux'
import { setHideInfoBanner as setHideInfoBannerAction } from '@redux/modules/global'
import { Alert, Button, Stack, Typography, IconButton, Collapse } from '@mui/material'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'

// import LearnMoreModal from '@shared/LearnMoreAboutFinanceModal'
import ApplyButton from '@shared/apply-button'
import { getRegion } from '@helpers/geo-location'
import { useServerSideLocation } from '@context/ServerSideLocation'

const PREQUALIFIED_LINK = `${process.env.GATSBY_SYNCHRONY_URL}&preQual=Y&sitecode=`

export const CollapseStyles = styled(Collapse)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    '& .MuiPaper-root': {
      position: 'relative',
      padding: '6px 0',
    },
  },
}))

export const ImgStyles = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    height: '47px',
    marginTop: '0px',
    position: 'absolute',
    left: '10px',
    top: '7px',
  },
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    top: '4px',
    right: '4px',
    padding: '2px',
  },
}))

const RtgFinancingCardContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '40px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: '8px',
  },
}))

const LeftLabel = styled(Typography)(({ theme }) => ({
  fontSize: '15px',
  lineHeight: '20px',
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    margin: '0',
    marginLeft: '94px !important',
  },
}))

const LeftDescription = styled(Typography)(({ theme }) => ({
  fontSize: '15px',
  lineHeight: '20px',
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
  },
}))

const ApplyButtonContainer = styled(Typography)(({ theme }) => ({
  justifyContent: 'center',
  spacing: 1,
  [theme.breakpoints.down('md')]: {
    justifyContent: 'left',
    paddingBottom: '2px',
    marginTop: '0 !important',
    marginLeft: '95px !important',
    maxWidth: '140px',
  },
}))

const AlertLink = ({ data }) => (
  <Button
    type="button"
    gtm-category="credit-card"
    gtm-action={data.label}
    gtm-label="header"
    variant="text"
    size="small"
    className="synchrony-prequal-learn-more"
    href="#"
    sx={{
      color: '#fff',
      fontSize: '12px',
      lineHeight: '16px',
      textDecoration: 'underline',
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
      margin: 0,
      padding: 0,
      marginLeft: '35px !important',
      position: { xs: 'absolute', md: 'relative' },
      bottom: { xs: '10px', md: '0' },
      right: { xs: '14px', md: '0' },
    }}
  >
    {data.label}
  </Button>
)

AlertLink.propTypes = {
  data: PropTypes.object.isRequired,
}

function InfoBanner({ isMobile, bgColor, left, right, isDrawerOpen }) {
  const hideOnMobile =
    isMobile &&
    ['/checkout', '/product/'].some(dir => {
      // "window" is not available during Server-Side Rendering.
      if (typeof window !== 'undefined') {
        return window?.location?.href?.includes(dir)
      }
      return false
    })
  const pageHasFinanceBanner =
    typeof document !== 'undefined' && document.getElementsByClassName('strapi-finance-banner')[0]

  const { disableFinanceBanner } = useServerSideLocation()
  const dispatch = useDispatch()
  const hideInfoBanner = useStore().getState()?.global?.hideInfoBanner ?? true
  const shouldNotOpen = disableFinanceBanner || hideInfoBanner || hideOnMobile || !pageHasFinanceBanner

  const alertRef = useRef(null)
  const [open, setOpen] = useState(!shouldNotOpen)

  const timeout = useMemo(() => {
    if (isMobile) {
      return 200
    }
    return open ? 0 : 'auto'
  }, [isMobile, open])

  const handleAlertClick = useCallback(
    e => {
      const invalidTags = ['BUTTON', 'A']
      if (!invalidTags.includes(e.target.tagName)) {
        navigate(left.button.url)
      }
    },
    [left],
  )

  function onClose() {
    setOpen(false)
    const cookies = new Cookies()
    cookies.set('__SHOULD_NOT_OPEN_INFO_BANNER__', true, {
      path: '/',
      secure: true,
      sameSite: 'lax',
      maxAge: 1 * 24 * 60 * 60, // 86400 || 1 day
    })
    dispatch(setHideInfoBannerAction(true))
  }

  return (
    <CollapseStyles
      in={open && (isMobile ? !isDrawerOpen : true)}
      easing={{ enter: 'ease-in' }}
      timeout={timeout}
      sx={{
        width: '100%',
      }}
      unmountOnExit
    >
      <Alert
        ref={alertRef}
        variant="filled"
        onClick={handleAlertClick}
        icon={false}
        action={
          <StyledIconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
            <CloseIcon sx={{ pointerEvents: 'none' }} fontSize="inherit" />
          </StyledIconButton>
        }
        sx={{
          cursor: 'pointer',
          background: bgColor,
          borderRadius: 0,
          '.MuiAlert-message': {
            width: '100%',
            padding: 0,
          },
        }}
      >
        <RtgFinancingCardContainer>
          <Stack justifyContent="left" direction="row" spacing={1} alignItems="center">
            <ImgStyles
              src={`https://assets.roomstogo.com/RU2_R1_Financing_Content_CN_CreditCard_Icon.png?h=${
                isMobile ? '60' : '30'
              }`}
              alt="Rooms to go finaincing card"
            />
            {left?.label && (
              <LeftLabel variant="body1" color="inherit" fontWeight="bold">
                {left.label}
              </LeftLabel>
            )}
            {left?.description && (
              <LeftDescription variant="body1" color="inherit">
                {left.description}
              </LeftDescription>
            )}
            {left?.button && <AlertLink data={left.button} />}
          </Stack>
          <ApplyButtonContainer direction="row">
            <ApplyButton
              asButton
              url={`${PREQUALIFIED_LINK}rgbcml5d1&modalapply=true`}
              rel="nofollow"
              category="credit-card"
              action="apply-now"
              className="button apply-button"
              label="banner"
              value="See If You Prequalify"
              sx={{
                borderRadius: '4px',
                padding: '7px 10px',
                backgroundColor: '#fff',
                color: bgColor,
                fontSize: '12px',
                lineHeight: '15px',
                fontWeight: 600,
                display: 'flex',
                height: '22px',
                minWidth: '140px',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '4px',
              }}
            >
              See If You Prequalify
            </ApplyButton>
          </ApplyButtonContainer>
        </RtgFinancingCardContainer>
      </Alert>
    </CollapseStyles>
  )
}

InfoBanner.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  bgColor: PropTypes.string.isRequired,
  left: PropTypes.object.isRequired,
  right: PropTypes.object.isRequired,
}

const StrapiInfoBannerQuery = graphql`
  query StrapiInfoBannerQuery {
    strapiHeader {
      TopInfoBanners {
        DefaultContent {
          ...StrapiInfoBannerFragment
        }
        Content_SE {
          ...StrapiInfoBannerFragment
        }
        Content_FL {
          ...StrapiInfoBannerFragment
        }
        Content_TX {
          ...StrapiInfoBannerFragment
        }
        Content_OOM {
          ...StrapiInfoBannerFragment
        }
      }
    }
  }
`

export default function AppbarAlert({ isMobile = false, isDrawerOpen = false }) {
  const data = useStaticQuery(StrapiInfoBannerQuery)
  const InfoBanners = data?.strapiHeader?.TopInfoBanners

  const props = useMemo(() => {
    const region = getRegion()
    const banner = InfoBanners?.[`Content_${region}`] || InfoBanners?.DefaultContent || null
    if (!banner) {
      return null
    }
    const leftLink = banner?.Left?.CallToAction || null
    const rightLink = banner?.Right?.CallToAction || null
    return {
      id: banner?.id,
      bgColor: banner?.BackgroundColor?.ColorHex || '#003566',
      left: {
        label: banner?.Left?.TitleText || null,
        description: banner?.Left?.DescriptionText || null,
        button: {
          // If there is no Link on the left side, "Learn More" button will be displayed instead (which will open a modal on click)
          label: leftLink?.DisplayText || 'Learn More',
          learnMoreModal: !leftLink || false,
          url: leftLink?.InternalUrl || leftLink?.ExternalUrl || null,
        },
      },
      right: {
        label: banner?.Right?.TitleText || null,
        description: banner?.Right?.DescriptionText || null,
        button: {
          label: rightLink?.DisplayText || null,
          learnMoreModal: false,
          url: rightLink?.InternalUrl || rightLink?.ExternalUrl || null,
        },
      },
    }
  }, [InfoBanners])

  if (!props) {
    return null
  }

  return <InfoBanner isMobile={isMobile} isDrawerOpen={isDrawerOpen} {...props} />
}

AppbarAlert.propTypes = {
  isMobile: PropTypes.bool,
  isDrawerOpen: PropTypes.bool,
}

export const StrapiInfoBannerFragment = graphql`
  fragment StrapiInfoBannerFragment on StrapiHeaderInfoBanner {
    id
    BackgroundColor {
      ColorHex
    }
    Left {
      TitleText
      DescriptionText
      CallToAction {
        ...StrapiLinkFragment
      }
    }
    Right {
      TitleText
      DescriptionText
      CallToAction {
        ...StrapiLinkFragment
      }
    }
  }
`
